// https://www.benmvp.com/blog/how-to-create-circle-svg-gradient-loading-spinner/
// https://stackoverflow.com/questions/22531861/ring-shaped-process-spinner-with-fading-gradient-effect-around-the-ring

import React from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { LoaderVariantProps, loader } from '@pt-group-fe/styled-system/recipes';

interface LoaderProps extends React.HtmlHTMLAttributes<HTMLDivElement>, LoaderVariantProps {
  size?: number;
  thikness?: number;
  color?: string;
}

const Loader = React.forwardRef<HTMLDivElement, LoaderProps>((props, ref) => {
  const { className, variant = 'default', size = 24, thikness = 1, color, ...rest } = props;

  return (
    <div
      ref={ref}
      className={cx(loader({ variant }), className)}
      style={{
        width: size,
        padding: size / (10 * (1 / thikness)),
        background: color
      }}
      {...rest}
    />
  );
});
Loader.displayName = 'Loader';

const LOADER_VARIANTS = ['default', 'primary', 'white'];

export { Loader, LOADER_VARIANTS };
export type { LoaderProps };

// return (
//   <div ref={ref} className={cx(root, className)} {...rest}>
//     <svg
//       className={cx(svg, svgCl)}
//       viewBox="0 0 200 200"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <defs>
//         <linearGradient id="spinner-secondHalf">
//           <stop offset="40%" stop-opacity="0" stop-color="currentColor" />
//           <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
//         </linearGradient>
//         <linearGradient id="spinner-firstHalf">
//           <stop offset="40%" stop-opacity="1" stop-color="currentColor" />
//           <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
//         </linearGradient>
//       </defs>

//       <g stroke-width="8">
//         <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
//         <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />

//         {/* <!-- 1deg extra path to have the round end cap --> */}
//         <path stroke="currentColor" stroke-linecap="round" d="M 4 100 A 96 96 0 0 1 4 98" />
//       </g>

//       {/* <animateTransform
//         from="0 0 0"
//         to="360 0 0"
//         attributeName="transform"
//         type="rotate"
//         repeatCount="indefinite"
//         dur="1300ms"
//       /> */}
//     </svg>
//   </div>
// );
